const LazyObjectCampainMetadataPanel = lazy(
  () => import("./campain-metadata-panel"),
);

export function ObjectCampainMetadataPanel(props) {
  return (
    <Suspense fallback={null}>
      <LazyObjectCampainMetadataPanel {...props} />
    </Suspense>
  );
}
